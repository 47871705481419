import React from "react";
import { Link, graphql } from "gatsby";
import Masonry from "react-masonry-css";
import Layout from "../../components/layout.en";
import SEO from "../../components/seo";
import SlickSlider from "react-slick";
import moment from "moment";
import BackgroundImage from "gatsby-background-image";

const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
};

const BlogList = ({ data }) => {
    const shortify = text => {
        const latinMap = {
            á: "a",
            à: "a",
            ã: "a",
            â: "a",
            ç: "c",
            é: "e",
            ê: "e",
            í: "i",
            ó: "o",
            õ: "o",
            ô: "o",
            ú: "u",
        };
        return text
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\-a-z0-9]/g, x => latinMap[x] || x)
            .replace(/[^\-a-z0-9]/g, "");
    };

    return (
        <Layout>
            <SEO title="Blog" description="Read our stories and be informed about the latest news and advices about e-commerce, omnichannel and marketing" lang="en" />
            <div style={{ position: "relative" }}>
                <BackgroundImage backgroundColor={`#040e18`} fluid={data.heroImage.childImageSharp.fluid} className="brook-breadcaump-area ptb--270 pt_md--190 pb_md--100 pt_sm--190 pb_sm--100 breadcaump-title-bar breadcaump-title-white" data-black-overlay="8" Tag="div">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcaump-inner text-center">
                                    <h1 className="heading heading-h1 text-white line-height-1 font-80">Blog</h1>
                                    <div className="bkseparator--30"></div>
                                    <h5 className="heading heading-h5 text-white line-height-1-95">
                                        Read our stories and be informed about the latest news and <br /> advices about e-commerce, omnichannel and marketing
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="unsplash-cred">
                        Photo by{" "}
                        <a href="https://unsplash.com/@jdiegoph?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank" rel="noreferrer">
                            Diego PH
                        </a>{" "}
                        on{" "}
                        <a href="https://unsplash.com/s/photos/blog?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank" rel="noreferrer">
                            Unsplash
                        </a>
                    </span>
                </BackgroundImage>
            </div>

            <div className="bk-blog-grid-area pt--70 pb--100 pt_md--80 pb_md--80 pb_sm--80 pt_sm--60 bg_color--5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-mesonry bk-blog-masonry clearfix">
                                <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid2" columnClassName="blog-33-33 mb--60">
                                    {data.blog.Cottonhat_blogList.map(item => {
                                        return (
                                            <div className="blog-grid">
                                                <SlickSlider classNameName="post-thumb blog-slick-initial poss_relative" spaceBetween={0} slidesToShow={1} slidesToScroll={1} arrows={true} infinite={true} style={{ height: "100%" }}>
                                                    <Link to={`/en/blog/${item.title.en_EN ? shortify(item.title.en_EN) : item.uri}`}>
                                                        {typeof item.photo !== "undefined" && typeof item.photo.url !== "undefined" && item.photo !== null && item.photo.url !== null ? (
                                                            <img src={`${item.photo.url}?w=480&h=480&fit=crop`} alt={item.title.en_EN ? item.title.en_EN : item.title} />
                                                        ) : (
                                                            <img src={`/images/portfolio/grid/metro3-3.jpg`} alt="sample" />
                                                        )}
                                                    </Link>
                                                </SlickSlider>
                                                <div className="post-content">
                                                    <div className="post-inner">
                                                        <h5 className="heading heading-h5">
                                                            <Link to={`/en/blog/${item.title.en_EN ? shortify(item.title.en_EN) : item.uri}`}>{item.title.en_EN ? item.title.en_EN : item.title}</Link>
                                                        </h5>
                                                        <div className="post-meta">
                                                            <div className="post-date">{moment(item.creat_default.createdAt)._isValid ? moment(item.creat_default.createdAt).format("LL") : null}</div>
                                                            <div className={moment(item.creat_default.createdAt)._isValid ? `post-category b` : `post-category`}>
                                                                {item.Category.map((cat, index) => (
                                                                    <Link>
                                                                        {cat.label && cat.label}
                                                                        {index < item.Category.length - 1 && <>,&nbsp;</>}
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Masonry>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default BlogList;

export const query1 = graphql`
    query {
        blog: wbapi {
            Cottonhat_blogList(project: "592d4cf5b549b452a450d829") {
                project_id
                title
                _id
                uri
                creat_default {
                    createdAt
                }
                normalized_name
                Category {
                    code
                    label
                }
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
        heroImage: file(relativePath: { eq: "blog.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    originalImg
                }
            }
        }
    }
`;
